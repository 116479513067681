import React from "react";
// import logo from "./logo.svg";
import { CKEditor } from "ckeditor4-react";
import "./App.css";
CKEditor.editorURL = "../ckeditor.js";
// import axios from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;

//fetch data from the server

function bgColor(item) {
  if (item.checked === 1) {
    return "itemLi bg-green-300";
  }
  if (item.checked === 0) {
    return "itemLi bg-white";
  }
}
function Buttons(props) {
  const thiss = props.this;
  if (
    thiss.state.currentCode + 1 < thiss.state.codes.length &&
    thiss.state.currentCode === 0
  ) {
    return (
      <button
        onClick={() => {
          if (thiss.state.currentCode + 1 < thiss.state.codes.length) {
            thiss.state.currentCode += 1;
            localStorage.setItem("currentCode", thiss.state.currentCode);
            thiss.fetchCode(thiss.state.codes[thiss.state.currentCode]);
            console.log(thiss.state.currentCode);
            window.location.reload();
          }
        }}
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded float-right mx-4 transition-all duration-500 mb-4"
      >
        Next
      </button>
    );
  } else if (
    thiss.state.currentCode + 1 < thiss.state.codes.length &&
    thiss.state.currentCode > 0
  ) {
    return (
      <div>
        <button
          onClick={() => {
            thiss.state.currentCode -= 1;
            localStorage.setItem("currentCode", thiss.state.currentCode);
            console.log(thiss.state.currentCode);

            thiss.fetchCode(thiss.state.codes[thiss.state.currentCode]);
            window.location.reload();
          }}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded float-left mx-4 transition-all duration-500 mb-4"
        >
          Previous
        </button>

        <button
          onClick={() => {
            if (thiss.state.currentCode + 1 < thiss.state.codes.length) {
              thiss.state.currentCode += 1;
              localStorage.setItem("currentCode", thiss.state.currentCode);
              thiss.fetchCode(thiss.state.codes[thiss.state.currentCode]);
              console.log(thiss.state.currentCode);

              window.location.reload();
            }
          }}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded float-right mx-4 transition-all duration-500 mb-4"
        >
          Next
        </button>
      </div>
    );
  } else if (
    thiss.state.currentCode + 1 === thiss.state.codes.length &&
    thiss.state.currentCode > 0
  ) {
    return (
      <button
        onClick={() => {
          thiss.state.currentCode -= 1;
          localStorage.setItem("currentCode", thiss.state.currentCode);
          thiss.fetchCode(thiss.state.codes[thiss.state.currentCode]);
          console.log(thiss.state.currentCode);
          window.location.reload();
        }}
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded float-left mx-4 transition-all duration-500 mb-4"
      >
        Previous
      </button>
    );
  } else {
    return <div></div>;
  }
}

function Firstuncked(props) {
  const thiss = props.this;
  if (thiss.state.firstUncked === null) {
    return "";
  }
  let firstUncked = thiss.state.firstUncked;
  // if firstUncked is in thiss.state.codes
  if (thiss.state.codes.includes(firstUncked)) {
    // get the number of the first unchecked code

    return (
      <div>
        The first unchecked code is: #
        {thiss.state.codes.indexOf(firstUncked) + 1}
      </div>
    );
  } else {
    return "asd";
  }
}

class App extends React.Component {
  // Constructor
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      DataisLoaded: false,
    };
  }

  // ComponentDidMount is used to
  // execute the code
  componentDidMount() {
    if (localStorage.getItem("currentCode") < 0) {
      localStorage.setItem("currentCode", 0);
    }
    fetch(baseURL + "/codes")
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          codes: json.codes,
          data: json,
          currentCode: parseInt(localStorage.getItem("currentCode")) || 0,
        });
        if (this.state.currentCode === undefined) {
          this.setState({
            currentCode: 0,
          });
          localStorage.setItem("currentCode", 0);
        }
        if (this.state.currentCode > this.state.codes.length - 1) {
          this.setState({
            currentCode: 0,
          });
          localStorage.setItem("currentCode", 0);
        }
        if (this.state.codes !== []) {
          this.fetchCode(this.state.codes[this.state.currentCode]);
        } else {
          this.setState({
            DataisLoaded: true,
          });
        }
      });

    fetch(baseURL + "/firstuncked").then((res) => {
      if (res.status === 200) {
        res.json().then((json) => {
          this.setState({
            firstUncked: json.code,
          });
        });
      } else {
        this.setState({
          firstUncked: null,
        });
      }
    });
  }
  fetchCode(code) {
    fetch(baseURL + "/" + code)
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          DataisLoaded: true,
          data: json,
        });
      });
  }
  handleGoto(e) {
    // e.target's parent
    let parent = e.target.parentElement;
    const value = parent.querySelector("input").value;
    if (
      value === "" ||
      parseInt(value) === 0 ||
      parseInt(value) > this.state.codes.length
    ) {
      return;
    }
    const code = parseInt(value) - 1;
    this.setState({ currentCode: code }, () => {
      localStorage.setItem("currentCode", this.state.currentCode);
      this.fetchCode(this.state.codes[this.state.currentCode]);
      window.location.reload();
    });
  }
  handleClick(e, item) {
    // e.target's parent
    // if input huName's value is not equal to item.huName
    let name = item.huName;
    let short_description = item.short_description;
    let long_description = item.long_description;
    let youtube = item.youtube;

    //find huName named input
    const nameE =
      e.target.parentNode.parentNode.querySelector("input[name='name']");
    if (nameE.value !== item.name) {
      // console.log(huNameE.value);
      name = nameE.value;
    }
    const youtubeE = e.target.parentNode.parentNode.querySelector(
      "input[name='youtube']"
    );
    if (youtubeE.value !== item.youtube) {
      // console.log(huNameE.value);
      youtube = youtubeE.value;
    }
    let short_descriptionE =
      e.target.parentNode.parentNode
        .querySelector(".short_description")
        .querySelector(".cke_wysiwyg_frame").contentDocument.body ||
      e.target.parentNode.parentNode
        .querySelector(".short_description")
        .querySelector(".cke_wysiwyg_frame").contentWindow.document.body;

    //get html code in huDescription
    const short_descriptionHTML = short_descriptionE.innerHTML;
    console.log(short_descriptionE.documentElement);
    if (short_descriptionHTML !== item.short_description) {
      // console.log(huDescriptionHtml);
      short_description = short_descriptionHTML;
    }
    const long_descriptionE =
      e.target.parentNode.parentNode
        .querySelector(".long_description")
        .querySelector(".cke_wysiwyg_frame").contentDocument.body ||
      e.target.parentNode.parentNode
        .querySelector(".long_description")
        .querySelector(".cke_wysiwyg_frame").contentWindow.document.body;
    //get html code in huDescription
    const long_descriptionHTML = long_descriptionE.innerHTML;
    if (long_descriptionHTML !== item.short_description) {
      // console.log(huDescriptionHtml);
      long_description = long_descriptionHTML;
    }
    let data = {
      name: name,
      code: item.code,
      link: item.link,
      short_description: short_description,
      long_description: long_description,
      youtube: youtube,
      checked: 1,
    };
    const url = baseURL + "/" + item.code;
    //post data to the server
    fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        // console.log(res.json());
        window.location.reload();
        // console.log(localStorage.getItem("currentCode"));
      })
      .catch((err) => console.log(err));
  }

  handleDownload() {
    const url = baseURL + "/export";
    const filename = "export.xlsx";
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  render() {
    const { DataisLoaded, data } = this.state;
    if (!DataisLoaded)
      return (
        <div>
          <h1> Pleses wait some time.... </h1>{" "}
        </div>
      );
    let item = data.item;
    if (data.item == null) {
      return (
        <div className="App">
          <h1 className="text-3xl font-bold"> LEIRAS.ALL4PETS.HU </h1> No items
          found
          <div className="p-4">
            <input type="file" name="table"></input>
            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 rounded  transition-all duration-500"
              onClick={() => {
                //get file from table input
                const file = document.querySelector("input[name='table']")
                  .files[0];
                if (file === undefined) {
                  return;
                }
                //post file to the server
                const formData = new FormData();
                formData.append("table", file);
                fetch(baseURL + "/", {
                  method: "DELETE",
                }).then(() => {
                  fetch(baseURL + "/upTable", {
                    method: "POST",
                    body: formData,
                  }).then((res) => {
                    // console.log(res.json());
                    window.location.reload();
                  });
                });
              }}
            >
              Upload
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className={"App " + bgColor(item)}>
          <h1 className="text-3xl font-bold inline-block text-left float-left p-3 w-3/5">
            {" "}
            LEIRAS.ALL4PETS.HU{" "}
          </h1>{" "}
          <div className="p-4">
            <input type="file" name="table"></input>
            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 rounded  transition-all duration-500"
              onClick={() => {
                //get file from table input
                const file = document.querySelector("input[name='table']")
                  .files[0];
                if (file === undefined) {
                  return;
                }
                //post file to the server
                const formData = new FormData();
                formData.append("table", file);
                fetch(baseURL + "/", {
                  method: "DELETE",
                }).then(() => {
                  fetch(baseURL + "/upTable", {
                    method: "POST",
                    body: formData,
                  }).then((res) => {
                    // console.log(res.json());
                    window.location.reload();
                  });
                });
              }}
            >
              Upload
            </button><input type="file" name="tablenodelete"></input>
            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 rounded  transition-all duration-500"
              onClick={() => {
                //get file from table input
                const file = document.querySelector(
                  "input[name='tablenodelete']"
                ).files[0];
                if (file === undefined) {
                  return;
                }
                //post file to the server
                const formData = new FormData();
                formData.append("table", file);
                
                  fetch(baseURL + "/upTable", {
                    method: "POST",
                    body: formData,
                  }).then((res) => {
                    // console.log(res.json());
                    window.location.reload();
                  });
                
              }}
            >
              Upload no delete
            </button>
            <button
              className="bg-blue-500 m-4 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded inline-block"
              onClick={this.handleDownload}
            >
              Download
            </button>
          </div>
          <div className={" block px-64px pb-0"}>
            <h2 className="text-2xl font-bold ">
              Code:
              <a href={item.link} target="_blank" rel="noreferrer">
                {" " + item.code}
              </a>{" "}
              #{this.state.currentCode + 1}/{this.state.codes.length}
            </h2>
            <div>
              Goto:
              <input
                type="number"
                className="outline-none rounded border border-gray-500 p-1 appearance-none mx-2"
              />
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 rounded  transition-all duration-500"
                onClick={(e) => this.handleGoto(e)}
              >
                Go
              </button>
            </div>
            <Firstuncked this={this} />
            <Buttons this={this}></Buttons>
            <div className="pt-12">
              <div className="p-4 px-8">
                <label className="text-lg font-bold  float-left">Name:</label>
                <input
                  type="text"
                  name="name"
                  defaultValue={item.name}
                  className="bg-gray-50 border-2 border-gray-400 text-gray-900 p-2 rounded w-full"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.handleEnter(e, item);
                    }
                  }}
                />
                <label className="text-lg font-bold  float-left">
                  Youtube Link:
                </label>

                <input
                  type="text"
                  name="youtube"
                  defaultValue={item.youtube}
                  className="bg-gray-50 border-2 border-gray-400 text-gray-900 p-2 rounded w-full"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.handleEnter(e, item);
                    }
                  }}
                />
              </div>
              <div className="p-4 px-8">
                <label className="text-lg font-bold  float-left">
                  Short Description:
                </label>
              </div>
              <div className="p-8 short_description">
                <CKEditor
                  initData={item.short_description}
                  config={{
                    extraPlugins: "font,justify",
                  }}
                />
              </div>
              <div className="p-4 px-8">
                <label className="text-lg font-bold  float-left">
                  Long Description:
                </label>
              </div>
              <div className="p-8 long_description">
                <CKEditor
                  initData={item.long_description}
                  config={{
                    extraPlugins: "font,justify",
                  }}
                />
              </div>
              <div>
                Goto:
                <input
                  type="number"
                  className="outline-none rounded border border-gray-500 p-1 appearance-none mx-2"
                />
                <button
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 rounded  transition-all duration-500"
                  onClick={(e) => this.handleGoto(e)}
                >
                  Go
                </button>
              </div>
              <Firstuncked this={this} />
              <Buttons this={this}></Buttons>
              <div>
                <button
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded float-right mx-4 transition-all duration-500"
                  onClick={(e) => {
                    this.handleClick(e, item);
                    if (this.state.currentCode + 1 < this.state.codes.length) {
                      this.state.currentCode++;
                      localStorage.setItem(
                        "currentCode",
                        this.state.currentCode
                      );

                      this.fetchCode(this.state.codes[this.state.currentCode]);
                    }
                    window.location.reload();
                  }}
                >
                  Update and Next
                </button>
                <button
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded float-right mx-4 transition-all duration-500"
                  onClick={(e) => this.handleClick(e, item)}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default App;
